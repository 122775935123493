import styled from "styled-components";

export const Logo = styled.div`
  width: 140px;
  height: 85px;
  background: url("/images/fca_icon.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 5px;
  margin-top: 20px;
  margin-bottom: -30px;
`;

export const MinLogo = styled.div`
    width: 100px;
    height: 50px;
    background: url('/images/fca_icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 10px;
    margin-left: 15px;
`;