import React from "react";

const Error404 = (props) => {
    return <div className="container">
        <h1 style={{ fontSize: "150px", textAlign: "center"}}>404</h1>
        <p style={{ textAlign: "center" }}>No pudimos encontrar lo que estabas buscando</p>
    </div>

}

export default Error404;