import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { auth, database } from "./firebase.config";
import "./App.css";
import PrivateRoute from "../src/shared/PrivateRoute";
import AdminRoute from "../src/shared/AdminRoute";
import history from "./helpers/history";
import Header from "./shared/Header";
import Error404 from "./shared/Error404";
import styled from "styled-components";
import { MinLogo } from "./styles/Logo";
import { CircularProgress } from "@material-ui/core";
import Loadable from "react-loadable";
import RegistroDeFirma from "../src/containers/hr/catastro/sign";
import { GetParams } from "./helpers/h";
import Loading from "./shared/Loading";
import WhiteListDomain from "./shared/WhiteListDomain";

function LoadingContainer(props) {
  if (props.error) {
    return (
      <div>
        Error! <button onClick={props.retry}>Retry</button>
      </div>
    );
  } else if (props.timedOut) {
    return (
      <div>
        Taking a long time... <button onClick={props.retry}>Retry</button>
      </div>
    );
  } else if (props.pastDelay) {
    return <Loading />;
  } else {
    return null;
  }
}

const Login = Loadable({
  loader: () => import("./containers/Login" /* webpackChunkName:"login" */),
  loading: LoadingContainer
});

const Home = Loadable({
  loader: () => import("./containers/Home" /* webpackChunkName:"home" */),
  loading: LoadingContainer
});

const CatastroDeAutorizaciones = Loadable({
  loader: () =>
    import("../src/containers/hr/catastro/" /* webpackChunkName:"catastro" */),
  loading: LoadingContainer
});

const Container = styled.div`
  display: flex;
  .izquierda {
    width: 150px;
  }
  .contenido {
    flex: 1;
  }
`;

class App extends Component {
  state = {
    authenticated: false,
    loading: true,
    currentUser: null,
    esFCA: null,
    redirectAfterLogin: null
  };

  componentWillMount() {
    auth().onAuthStateChanged(async user => {
      if (user && !user.isAnonymous) {
        const dominio = user.email.split("@")[1];        
        if (WhiteListDomain.includes(dominio)) {
          const complementaryData = await database()
            .ref(`/users/${user.uid}`)
            .once("value");

          const DEFAULTData = {
            name: user.displayName,
            email: user.email,
            admin: false,
            area: "FCA"
          };

          if (!complementaryData.val()) {
            await database()
              .ref(`/users/${user.uid}`)
              .set({
                name: user.displayName,
                email: user.email,
                admin: false,
                area: "FCA"
              });
          }

          user.fca = !complementaryData.val()
            ? DEFAULTData
            : complementaryData.val();

          if (user.fca) {
            user.isAdmin = user.fca.admin;
            user.sapid = user.fca.sapid;
          }

          this.setState({
            authenticated: true,
            currentUser: user,
            loading: false
          });

          const params = GetParams(window.location.href);
          if (
            params.redirect &&
            document.location.pathname !== params.redirect
          ) {
            history.push({
              pathname: params.redirect,
              search: `?from=${window.location.pathname}`
            });
          }
        }
      } else {
        const params = GetParams(window.location.href);
        this.setState({
          authenticated: false,
          currentUser: null,
          loading: false,
          redirectAfterLogin: params.redirect
        });
      }
    });
  }

  render() {
    const { authenticated, loading, currentUser } = this.state;
    if (loading) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: "100vw",
            height: "100vh"
          }}
        >
          <MinLogo />
          <CircularProgress style={{ color: "#5C88DA" }} size="30px" />
        </div>
      );
    }

    return (
      <Router history={history}>
        <div>
          {authenticated && <Header currentUser={currentUser} />}
          <Container>
            <div className="contenido">
              <Switch>
                <PrivateRoute
                  exact
                  path="/"
                  component={!authenticated ? Login : Home}
                  currentUser={currentUser}
                  authenticated={true}
                  redirect={GetParams(document.location.href).redirect}
                />
                <AdminRoute
                  exact
                  path="/rrhh/catastro"
                  component={CatastroDeAutorizaciones}
                  currentUser={currentUser}
                  authenticated={authenticated}
                  admin={authenticated && currentUser.isAdmin}
                  area={"RRHH"}
                  redirect={GetParams(document.location.href).redirect}
                />
                <PrivateRoute
                  exact
                  path="/rrhh/catastro/sign"
                  component={RegistroDeFirma}
                  currentUser={currentUser}
                  authenticated={authenticated}
                  redirect={GetParams(document.location.href).redirect}
                />
                <Route component={Error404} />
              </Switch>
            </div>
          </Container>
        </div>
      </Router>
    );
  }
}

export default App;
