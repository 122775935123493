import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255, 1);
  z-index: 15000;
`;

export default class Loading extends Component {
  render() {
    return (
      <LoadingContainer>
        <CircularProgress size={70} />
      </LoadingContainer>
    );
  }
}
