import React, { Component } from "react";
import Button from "@material-ui/core/Button";

export default class Help extends Component {
  verTutorial = () => {
    window.open(
      "https://drive.google.com/file/d/15sWtIpt5hZlcGxBeCB1r_qeU4s6h2BNQ/view",
      "_blank"
    );
    this.props.close();
  };

  render() {
    return (
      <div>
        Iniciamos la implementación del nuevo catastro de firmas dinámico, para
        lo cual solicitamos registre su firma siguiendo el tutorial a
        continuación. <br /> Esta plataforma asocia la firma digital del
        empleado (a ser registrada según tutorial) con sus respectivas delegas,
        permitiendo a los entes de control validar las aprobaciones manuales
        necesarias en los procesos internos de la organización. Esta nueva
        herramienta representa un esfuerzo para mejorar los procesos internos
        tornándolos más ágiles y eficaces. Utilice el video explicativo debajo
        para registrar su firma en el portal. <br />
        Por dudas o consultas relativas al proceso contactar con el HRBP o el
        área de Organización. Agradecemos su colaboración en este proceso.
        <Button
          onClick={this.verTutorial}
          variant="contained"
          color="primary"
          style={{ display: "block", background: "#0D3974", marginTop: "10px" }}
        >
          Ver tutorial
        </Button>
      </div>
    );
  }
}
