import React, { Component } from "react";
import styled from "styled-components";
import SignaturePad from "react-signature-pad-wrapper";
import Fab from "@material-ui/core/Fab";
import CreateIcon from "@material-ui/icons/CreateRounded";
import DeleteIcon from "@material-ui/icons/DeleteSweepRounded";
import UndoIcon from "@material-ui/icons/UndoRounded";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Help from "./help";
import CircularProgress from "@material-ui/core/CircularProgress";

import { storage, database } from "../../../firebase.config";

const w = window,
  d = document,
  e = d.documentElement,
  g = d.getElementsByTagName("body")[0],
  x = w.innerWidth || e.clientWidth || g.clientWidth;

const SignatureContainer = styled.div`
  .container {
    padding: 16px;
    text-align: center;
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    .welcome {
      font-size: 30px;
      font-weight: 600;
    }
    .subWelcome {
      font-size: 20px;
      font-weight: 300;
    }
    .help {
      font-family: "Roboto", Arial, Helvetica, sans-serif;
      font-size: 14px;
      padding: 0;
      margin-bottom: 20px;
    }
    color: #fff;
  }
`;

const SignatureBox = styled.div`
  position: ${props => (props.fullscreen ? "absolute" : "initial")} !important;
  top: 0;
  left: 0;
  z-index: 99;
  canvas {
    background: #fff;
    width: ${props => (props.fullscreen ? x + "px" : "100%")} !important;
    @media screen and (min-width: 1200px) {
      height: "300px";
    }
    @media screen and (orientation: portrait) {
      width: 100% !important;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  margin-top: 20px;
  .space {
    flex: 1;
  }
`;

const SendingSignature = styled.div`
  .container {
    padding: 16px;
    text-align: center;
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    padding-top: 30px;
    &-title {
      font-size: 30px;
      font-weight: 600;
      color: #fff;
    }
    &-subtitle {
      font-size: 20px;
      font-weight: 300;
      margin-top: 30px;
      color: #fff;
      font-family: "Roboto", Arial, Helvetica, sans-serif;
    }
  }
`;

const ButtonHelp = styled(Button)`
  .help {
    @media screen and (min-width: 1200px) {
      margin-bottom: -70px !important;
    }
  }
`;

const SignatureThumbnail = styled.img`
  background: #fff;
  width: 100%;
  height: auto;
  border-radius: 5px;
  color: black;
  padding: 10px;
  box-shadow: 0 2px 3px 0 rgba(60, 64, 67, 0.3),
    0 6px 10px 4px rgba(60, 64, 67, 0.15) !important;
  margin-bottom: 15px;
`;

const SignatureShowcase = styled.div`
  .container-title {
    margin-bottom: -25px;
  }
`;

const FullscreenContainer = styled.div`
  &.isfullscreen {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
  }
`;

export default class RegistroDeFirma extends Component {
  state = {
    status: "LOADING",
    error: "",
    openDialog: false,
    openDialogDelete: false,
    openDialogConfirm: false,
    openDialogHelp: false,
    signatureBase64: "",
    update: false,
    fullscreen: false
  };

  componentDidMount() {
    const body = document.querySelector("body");
    body.style.background = "#1A73E8";

    const email = this.props.currentUser.email.split(".").join("");

    const ref = database().ref(`catastro/${email}`);
    ref.once("value", snapshot => {
      const data = snapshot.val();

      if (data) {
        if (data.signature1 === "") {
          this.setState({
            status: "INIT"
          });
          return true;
        }
        if (data.signature1 !== "") {
          this.setState({
            data,
            status: "DONE",
            update: true
          });
        }
      } else {
        this.setState({
          status: "NOTELIGIBLE"
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.status !== this.state.status) {
      if (this.state.status === "INIT") {
        if (localStorage.getItem("data")) {
          this.signaturePad.fromData(JSON.parse(localStorage.getItem("data")));
        }
        const canvas = document.querySelector("canvas");
        canvas.addEventListener("touchmove", () => {
          localStorage.setItem(
            "data",
            JSON.stringify(this.signaturePad.toData())
          );
        });
        canvas.addEventListener("mousemove", () => {
          localStorage.setItem(
            "data",
            JSON.stringify(this.signaturePad.toData())
          );
        });
      }
    }
  }

  componentWillUnmount() {
    const body = document.querySelector("body");
    body.style.background = "#FFFFFF";
  }

  handleClickOpen = () => {
    this.setState({ openDialog: true });
  };

  handleClose = where => {
    this.setState({ [where]: false });
  };

  save = () => {
    if (this.signaturePad.isEmpty()) {
      this.setState({
        openDialog: true
      });
      return false;
    }
    this.setState({
      openDialogConfirm: true,
      signatureBase64: this.signaturePad.toDataURL("image/png")
    });
    localStorage.setItem("data", "");
  };

  undo = () => {
    var data = this.signaturePad.toData();
    if (data) {
      data.pop(); // remove the last dot or line
      this.signaturePad.fromData(data);
      localStorage.setItem("data", JSON.stringify(data));
    }
  };

  clear = () => {
    this.signaturePad.clear();
    localStorage.removeItem("data");
    this.setState({
      openDialogDelete: false
    });
  };

  uploadSignature = () => {
    this.setState({
      status: "SENDING"
    });

    const image = this.state.signatureBase64.split(",")[1];

    const uploadTask = storage()
      .ref("/catastro/signature")
      .child(`${this.props.currentUser.displayName}-${+new Date()}`)
      .putString(image, "base64", {
        contentType: "image/png"
      });
    uploadTask.on(
      storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      snapshot => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        switch (snapshot.state) {
          case storage.TaskState.PAUSED: // or 'paused'
            break;
          case storage.TaskState.RUNNING: // or 'running'
            break;
          default:
            return false;
        }
      },
      error => {
        console.error(error);
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then(URL => {
          const user = this.props.currentUser;
          const email = user.email.split(".").join("");

          database()
            .ref(`catastro/${email}`)
            .set({
              uid: user.uid,
              email: user.email,
              name: user.displayName,
              signature1: !this.state.update ? URL : this.state.data.signature1,
              signature2: this.state.update ? URL : ""
            })
            .then(() => document.location.reload());

          return URL;
        });
      }
    );
  };

  registrarOtra = () => {
    if (localStorage.getItem("data")) {
      localStorage.removeItem("data");
    }
    this.setState({
      status: "INIT",
      update: true
    });
  };

  goFullscreen = () => {
    document.querySelector("#js-fullscreen").classList.add("isfullscreen");
    this.setState({
      fullscreen: !this.state.fullscreen
    });
  };

  render() {
    return (
      <div>
        {this.state.status === "LOADING" && (
          <SignatureContainer>
            <div className="container">
              <CircularProgress
                style={{ color: "#FFF", marginTop: "20px" }}
                thickness={5}
                size={40}
              />
            </div>
          </SignatureContainer>
        )}
        {this.state.status === "INIT" && (
          <SignatureContainer>
            <Dialog
              open={this.state.openDialog}
              onClose={() => {
                this.handleClose("openDialog");
              }}
            >
              <DialogTitle id="alert-dialog-title">{"Atención"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Es necesario que antes de enviar, dibujes tu firma en el
                  recuadro blanco.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    this.handleClose("openDialog");
                  }}
                  variant="contained"
                  color="primary"
                  autoFocus
                >
                  Entendido
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={this.state.openDialogDelete}
              onClose={() => {
                this.handleClose("openDialogDelete");
              }}
            >
              <DialogTitle id="alert-dialog-title">{"Atención"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  ¿Estás seguro de limpiar tu firma?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.clear} color="primary" autoFocus>
                  Continuar
                </Button>
                <Button
                  onClick={() => {
                    this.handleClose("openDialogDelete");
                  }}
                  variant="contained"
                  color="primary"
                  autoFocus
                >
                  Cancelar
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={this.state.openDialogConfirm}
              fullWidth={true}
              maxWidth={"sm"}
            >
              <DialogTitle id="alert-dialog-title">
                {"Confirma tu firma"}
              </DialogTitle>
              <DialogContent>
                <p>
                  {this.props.currentUser.displayName.split(" ")[0]}, antes de
                  enviar su firma, es necesario que la confirme
                </p>
                <p style={{ margin: 0, fontSize: "12px" }}>Tu firma: </p>
                <img
                  alt="Tu firma"
                  src={this.state.signatureBase64}
                  width="100%"
                  style={{ border: "1px solid #CCC", padding: "16px" }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    this.handleClose("openDialogConfirm");
                  }}
                  color="primary"
                  autoFocus
                >
                  Modificar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.uploadSignature}
                  autoFocus
                >
                  Confirmar
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={this.state.openDialogHelp} fullWidth={false}>
              <DialogTitle id="alert-dialog-title">
                {"¿Necesitas ayuda?"}
              </DialogTitle>
              <DialogContent>
                <Help
                  close={() => {
                    this.handleClose("openDialogHelp");
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    this.handleClose("openDialogHelp");
                  }}
                  color="primary"
                  autoFocus
                >
                  Entendido
                </Button>
              </DialogActions>
            </Dialog>

            <div className="container">
              <h4 className="welcome">
                Hola {this.props.currentUser.displayName.split(" ")[0]}
              </h4>
              <p className="subWelcome">Necesitamos registrar su firma</p>
              <p className="help">
                Utilice su dedo o lapiz (si esta disponible) para dibujar su
                firma en el recuadro blanco y luego presione el botón "Firmar".{" "}
                <br /> Si usted tiene 2 firmas, primero registre la primera y
                luego podra registrar la otra.
              </p>
              <p>Te recomendamos usar tu celular para registrar la firma.</p>
              <FullscreenContainer id="js-fullscreen">
                <SignatureBox fullscreen={this.state.fullscreen}>
                  <SignaturePad
                    id="sign"
                    redrawOnResize={true}
                    options={{
                      minWidth: 0.5,
                      maxWidth: 2.5,
                      penColor: "#000"
                    }}
                    ref={ref => (this.signaturePad = ref)}
                  />
                </SignatureBox>

                <Actions>
                  <Fab
                    variant="extended"
                    onClick={this.save}
                    style={{ background: "#FFF" }}
                  >
                    <CreateIcon style={{ marginRight: "10px" }} />
                    Firmar
                  </Fab>
                  <span className="space" />
                  <Fab
                    onClick={this.undo}
                    style={{ background: "#FFF", marginRight: "10px" }}
                  >
                    <UndoIcon />
                  </Fab>
                  <Fab
                    onClick={() => {
                      this.setState({ openDialogDelete: true });
                    }}
                    style={{ background: "#FFF" }}
                  >
                    <DeleteIcon />
                  </Fab>
                </Actions>
              </FullscreenContainer>
              <ButtonHelp
                className="help"
                style={{ color: "#FFF", marginTop: "15px" }}
                onClick={() => {
                  this.setState({ openDialogHelp: true });
                }}
              >
                Necesito ayuda
              </ButtonHelp>
            </div>
          </SignatureContainer>
        )}

        {this.state.status === "SENDING" && (
          <SendingSignature>
            <div className="container">
              <div className="container-title">
                {this.props.currentUser.displayName.split(" ")[0]}, estamos
                registrando su firma
              </div>
              <CircularProgress
                style={{ color: "#FFF", marginTop: "20px" }}
                thickness={5}
                size={80}
              />
              <p className="container-subtitle">Ya estamos terminando</p>
            </div>
          </SendingSignature>
        )}

        {this.state.status === "DONE" && (
          <SendingSignature>
            <div className="container">
              <div className="container-title">
                ¡Gracias {this.props.currentUser.displayName.split(" ")[0]}!
              </div>
              <p className="container-subtitle">
                {this.state.data.signature2 !== "" &&
                  `Sus firmas ya se encuentran registradas en nuestra base de datos.`}
                {this.state.data.signature2 === "" &&
                  `Su firma ya se encuentra registrada en nuestra base de datos.`}
                <br />
              </p>
              <SignatureShowcase>
                <p className="container-subtitle">
                  {this.state.data.signature2 !== "" &&
                    `Estas fueron las firmas que registro anteriormente.`}
                  {this.state.data.signature2 === "" &&
                    `Esta fue la firma que registro`}
                  <br />
                </p>
                <div className="row">
                  {this.state.data.signature1 !== "" &&
                    this.state.data.signature2 === "" && (
                      <div className="col-sm">
                        <SignatureThumbnail
                          src={this.state.data.signature1}
                          style={{ width: "50%" }}
                        />
                      </div>
                    )}

                  {this.state.data.signature1 !== "" &&
                    this.state.data.signature2 !== "" && (
                      <div className="col-sm">
                        <SignatureThumbnail src={this.state.data.signature1} />
                      </div>
                    )}

                  {this.state.data.signature2 !== "" && (
                    <div className="col-sm">
                      <SignatureThumbnail src={this.state.data.signature2} />
                    </div>
                  )}
                </div>
                <p className="container-subtitle">
                  Por cualquier duda o inconveniente, puede contactarse a{" "}
                  <strong>orgrrhh@fcagroup.com</strong>
                </p>
              </SignatureShowcase>
              {this.state.data.signature2 === "" && (
                <div>
                  <p className="container-subtitle"> ¿Tiene otra firma? </p>
                  <Button
                    style={{ color: "#FFF", marginTop: "15px" }}
                    onClick={this.registrarOtra}
                  >
                    Registrar otra firma
                  </Button>
                </div>
              )}
            </div>
          </SendingSignature>
        )}

        {this.state.status === "NOTELIGIBLE" && (
          <SendingSignature>
            <div className="container">
              <div className="container-title">
                Hola {this.props.currentUser.displayName.split(" ")[0]}
              </div>
              <p className="container-subtitle">
                Lo sentimos, pero en este momento no existe una solicitud para
                que registre su firma. <br />
                En caso que esto fuera incorrecto, por favor comuniquese a
                <strong> orgrrhh@fcagroup.com</strong>
              </p>
            </div>
          </SendingSignature>
        )}
      </div>
    );
  }
}
