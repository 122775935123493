import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/database";

firebase.initializeApp({
  apiKey: "AIzaSyDBckGRc4U-1OLeOW9W6btHw4ivTVcwvQY",
  authDomain: "catastro.fiatauto.com.ar",
  databaseURL: "https://fca-digital.firebaseio.com",
  projectId: "fca-digital",
  storageBucket: "fca-digital.appspot.com",
  messagingSenderId: "537504899348",
  appId: "1:537504899348:web:c5d76724edc0c538722201"
});

const auth = firebase.auth;
const storage = firebase.storage;
const database = firebase.database;

export { auth, storage, database };
