import React, { Component } from "react";
import styled from "styled-components";
import { MinLogo } from "../styles/Logo";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { auth } from "../firebase.config";

const HeaderContainer = styled.div`
  display: flex;
  padding-left: 15px;
  align-items: center;
  .takeAllSpace {
    flex: 1;
  }
  background: #fff;
`;

const AdminBadge = styled.span`
  background: #ff4f81;
  border-radius: 24px;
  padding: 10px;
  color: #fff;
  font-size: 12px;
`;

const AreaBadge = styled.span`
  background: #ffc168;
  border-radius: 24px;
  padding: 10px;
  color: #fff;
  font-size: 12px;
  margin-right: 10px;
`;

export default class Header extends Component {
  state = {
    anchorEl: null
  };


  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  cerrarSesion = async () => {
    await auth().signOut();
  };

  render() {
    const { photoURL, isAdmin, fca } = this.props.currentUser;
    const { anchorEl } = this.state;
    return (
      <HeaderContainer>
        <MinLogo />
        <span className="takeAllSpace" />
        {fca && <AreaBadge>{fca.area}</AreaBadge>}
        {isAdmin && <AdminBadge>Admin</AdminBadge>}
        <IconButton
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <Avatar src={photoURL} />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.cerrarSesion}>Cerrar Sesión</MenuItem>
        </Menu>
      </HeaderContainer>
    );
  }
}
